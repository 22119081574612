@import "scssShared/variables";

$header-height: 70px;

.header {
  height: $header-height;
  box-sizing: border-box;
  background-color: $color_grey_secondary_menu;
  border-bottom: 1px solid #dddddd;
  display: flex;
  align-items: center;
}

.headerInner {
  width: 100%;
  max-width: 1200px;
  margin: 0 20px;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  height: 100%;
  position: relative;
}

.title {
  font-weight: bold;
  font-size: 21px;
  letter-spacing: -0.02em;
  color: $color_black;
  margin: 0;
}

.content {
  margin: 20px;
  width: 100%;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - $header-height - 58px); // Sorry magic number

  @media (max-width: 1299px) {
    & {
      margin: 20px 10px;
    }
  }
}

.backButton {
  font-size: 21px;
  display: inline;
  padding: 0px 10px;
  margin-left: -10px;
  box-sizing: border-box;
  width: auto;
  height: auto;
}
