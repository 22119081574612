@import "scssShared/variables.scss";
@import "scssShared/baseVars.scss";

.attributes-header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;

  .info-tooltip-icon {
    font-size: 14px;
    color: $color_grey;
    margin-left: 10px;
  }

  .search-form-with-select {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
}

.attributes-container {
  .no-border {
    border-bottom: none !important;
  }

  .attribute-name-cell {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;

    .attribute-name {
      font-size: 16px;
      color: $color_black;
      font-weight: 700;
    }

    .badge {
      margin-left: 5px;
    }

    .sub-attrs {
      margin: 0;
      padding: 0;

      li {
        list-style-type: "– ";
        list-style-position: inside;
        font-size: 12px;
        line-height: 16px;
        color: $color_grey_lighter;
        font-weight: normal;
      }
    }
  }

  .attribute-examples {
    .examples-outer-wrapper {
      width: max-content;
      max-width: 100%;
      box-sizing: border-box;
      border: $border_grey_light_thin;
      border-radius: $br_medium;
      padding: 10px;
      background-color: white;
    }

    .examples-title {
      font-size: 11px;
      letter-spacing: 1px;
      color: $color_grey;
      text-transform: uppercase;
      font-weight: bold;
    }

    .examples-wrapper {
      display: flex;
      flex-wrap: wrap;
      margin-top: 5px;
    }

    .attribute-example-value {
      margin: 2.5px 5px 2.5px 0;
      white-space: normal;
      display: flex;
      align-items: center;
      padding: 4px 8px;
      line-height: 12px;
      height: auto;
      min-height: 24px;
      word-break: break-word;
    }
  }

  .attribute-source {
    gap: 10px;

    .source-icon {
      width: 35px;
    }

    .source-n-count {
      .count {
        font-size: 12px;
        color: $color_grey;
      }
    }
  }

  .attribute-tags {
    line-height: 35px;
  }

  .attribute-description {
    white-space: pre-line;
  }

  .attribute-label {
    cursor: pointer;
    white-space: normal;
    min-height: 24px;
    height: auto;
    line-height: 12px;
    padding-top: 6px;
    padding-bottom: 6px;
    margin: 0 5px 5px 0;
  }
}
